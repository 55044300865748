@import '../../styles/colors';
@import '../../styles/mixin';

.HeaderContainer {
  width: 100%;
  @extend .display-flex;
  @extend .flex-center;
  @extend .bsbb;
  padding: 10px 0;
  @media screen and (min-width: 768px) {
    padding: 20px 0;
  }
  @media screen and (min-width: 1024px) {
    padding: 50px 0;
  }
}

.Header {
  @extend .responsive-width;
  @extend .display-flex;
  @extend .flex-center;
  a {
    width: 100%;
    @extend .responsive-width;
    @extend .display-flex;
    @extend .flex-center;
  }
  img {
    max-height: 200px;
    max-width: 80%;
  }
}

.MainContainer {
  width: 100%;
  @extend .display-flex;
  @extend .flex-center;
  @extend .bsbb;
  padding: 10px;
}

.Main {
  @extend .responsive-width;
  @extend .display-flex;
  @extend .flex-center;
  @extend .flex-direction-column;
  // @extend .bsbb;
}

.Footer {
  width: 100%;
  text-align: center;
  color: $grey;
  font-size: 12px;
  @extend .bsbb;
  padding-bottom: 20px;
  @extend .display-flex;
  @extend .flex-direction-column;
  @extend .flex-center;
}

.Logo {
  height: 20px;
  margin: 10px 0;
}
