@import 'colors';
@import 'mixin';

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: $grey-100;
}

body,
div,
input,
select,
textarea,
a,
button,
pre {
  vertical-align: top;
  border: 0;
  outline: none;
  font-family: 'Figtree', sans-serif;
  color: $text-color;
  font-size: 14px;
}
textarea {
  resize: none;
}

a,
button {
  @extend .trstn;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    @extend .trstn;
  }
}

button {
  cursor: pointer;
  background: transparent;
}

input[type='submit'],
button {
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
    @include opacity(0.5);
  }
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--input-font-color);
  opacity: 0.5; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--input-font-color);
  opacity: 0.5;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--input-font-color);
  opacity: 0.5;
}

label {
  input,
  textarea,
  select {
    padding: var(--input-padding);
    background: var(--input-background);
    border: var(--input-border);
    border-radius: var(--input-border-radius);
    color: var(--input-font-color);
    font-size: var(--input-font-size);
    @extend .bsbb;
  }
}

*:focus {
  outline: 0 none;
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}
